import React,{ useState } from 'react';
import './App.css';

function App() {

  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [combinedText, setCombinedText] = useState('');
  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([combinedText], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.jsonl";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const updateHeaders = () => {
  setCombinedText(`${combinedText}\n{"messages": [{"role": "system", "content": "${text1}"}, {"role": "user", "content": "${text2}"}, {"role": "assistant", "content": "${text3}"}]}`);
    setText1('');
    setText2("");
    setText3("");
    console.log(combinedText);
};
  return (
    <div className="App">
      <div className="right-column">
      <div class="text-area-container">
            <div>
            <h1 id="header1">System prompt</h1>
            <textarea id="textArea1" rows="4" cols="50" onChange={(e) => setText1(e.target.value)}
            placeholder="Enter text for header 1" value={text1}></textarea>
            </div>
            <div>
            <h1 id="header2">User prompt</h1>
            <textarea id="textArea2" rows="4" cols="50" onChange={(e) => setText2(e.target.value)}
            placeholder="Enter text for header 2" value={text2}></textarea>
            </div>
            <div>
            <h1 id="header3">Assitant prompt</h1>
            <textarea id="textArea3" rows="4" cols="50" onChange={(e) => setText3(e.target.value)}
            placeholder="Enter text for header 3" value={text3}></textarea>
            </div>
        </div>
        <div className="container">
        <button onClick={updateHeaders} className="download-button">Update Conent</button></div>
        
      </div>
      
      <div className="right-column">
        <textarea value={combinedText} className="big-text-box" placeholder="Enter text here..."></textarea>
      <div className="container">
      <button onClick={handleDownload} className="download-button">Download</button>
        </div>  
        
      </div>
    </div>
  );
}

export default App;
